import { render, staticRenderFns } from "./Entroncamento.vue?vue&type=template&id=274df5af&scoped=true&"
import script from "./Entroncamento.vue?vue&type=script&lang=js&"
export * from "./Entroncamento.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274df5af",
  null
  
)

export default component.exports